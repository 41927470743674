<engage-page-header></engage-page-header>
<div class="bg" [ngClass]="bgClass">
  <div class="container body-content">
    <div class="card customFontSize payform">
      <div class="card-body">
        <div class="container-fluid">
          <h4>e-KYC Link Invalid And/Or Expired</h4>
          <p>
            The request for identity verification through this link has expired or invalid. Please call BMW Customer
            Relations at 03 27192688 for assistance.
            <br />
            Thank you.
          </p>

        </div>
      <div>
    </div>
  </div>
</div>