export class KYCStatusRequestViewModel {
  public Id: number;
  public ScreeningNo: string;

}
export class KYCStatusResponseViewModel {
  public customerName: string;
  public isCBTokenExpired: boolean;
  public ocrStatusId: number;
  public refrenceNo: string;
  public screeningID: string;
  public status: number;
  public statusMessage: string;
  public source: string;
  public deviceType: string;
  public sourceReferenceNo: string;
  public sqStatusId: number;
}
