import { Component, OnInit } from '@angular/core';
import { ThemeService } from 'src/app/_services/theme.service';
import { environment } from 'src/environments/environment';

const isIEOrEdge = /msie\s|trident\/|edge\/|firefox\//i.test(window.navigator.userAgent);

@Component({
  selector: 'engage-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

  engageLogoPath: string = '';
  homepage: string = '';
  bgClass: string = '';

  constructor(public themeservice: ThemeService) { }

  ngOnInit() {
    this.bgClass = this.themeservice.getMake();

    switch(this.bgClass) {
      case "BMW":
        this.homepage = environment.EngageBMW_URL;
        this.engageLogoPath = '../../../assets/img/bmwEngageLogo.png';
        break;
      case "MINI":
        this.homepage = environment.EngageMini_URL;
        this.engageLogoPath = '../../../assets/img/MiniEngage.svg';
        break;
      case "Motorrad":
        this.homepage = environment.EngageMotorrad_URL;
        this.engageLogoPath = '../../../assets/img/bmw_motorrad_logo.svg';
        break;
    }
  }
}