import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { TokenRequestViewModel } from "../e-kyc/_models/tokenRequestViewModel";
import { TokenResponseViewModel } from "../e-kyc/_models/tokenResponseViewModel";
import { AppUserAuth } from "../_security/app-user-auth";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": environment.UI_ENDPOINT,
  })
};
const eKYC_API_URL = environment.API_ENDPOINT;

@Injectable()
export class SecurityService {
  endpoint: string = "/api/V1/securitytoken/getToken";

  securityObject: AppUserAuth = new AppUserAuth();
  tokenReqViewModel: TokenRequestViewModel = new TokenRequestViewModel();

  constructor(private http: HttpClient) { }

  updateJWTToken(reqSource: string) {
    return new Promise(resolve => {
      let isTokenExpired = this.isTokenExpired(reqSource);
      if (!isTokenExpired) {
        resolve(true);
      } else {
        this.getNewJwtToken(reqSource).subscribe(resp => {
          if (resp) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      }
    });
  }

  isTokenExpired(reqSource: string, token?: string): boolean {
    if (!token) token = this.getJwtToken(reqSource);
    if (!token) return true;

    var claims = JSON.parse(atob(token.split(".")[1]));
    const date = new Date(claims.exp * 1000);

    if (date === undefined) return false;
    return !(date.valueOf() > new Date().valueOf());
  }
  getJwtToken(reqSource: string): string {
    if (reqSource === "eKYC") {
      return localStorage.getItem("bearerToken");
    } else {
      return null;
    }
  }
  getNewJwtToken(reqSource: string): Observable<TokenResponseViewModel> {
    let apiSourceUrl = "";
    if (reqSource === "eKYC") {
      apiSourceUrl = eKYC_API_URL;
    } else {
      return null;
    }
    this.resetSecurityObject(reqSource);
    const ekycTokenRequestViewModel = new TokenRequestViewModel();
    ekycTokenRequestViewModel.Source = environment.Source;

    return this.http
      .post<TokenResponseViewModel>(
        `${environment.API_ENDPOINT}${this.endpoint}`,
        ekycTokenRequestViewModel,
        httpOptions
      )
      .pipe(
        tap(resp => {
          Object.assign(this.securityObject, resp);
          this.saveJwtToken(reqSource, this.securityObject.bearerToken);
        })
      );
  }
  saveJwtToken(reqSource: string, token: string) {
    if (reqSource === "eKYC") {
      return localStorage.setItem("bearerToken", token);
    }
  }
  getKYCToken(ekycTokenRequestViewModel: TokenRequestViewModel) {
    this.resetSecurityObject("eKYC");

    return this.http
      .post<TokenResponseViewModel>(
        `${environment.API_ENDPOINT}${this.endpoint}`,
        ekycTokenRequestViewModel,
        httpOptions
      )
      .pipe(
        tap(resp => {
          // Use object assign to update the current object
          // NOTE: Don't create a new AppUserAuth object
          //       because that destroys all references to object
          Object.assign(this.securityObject, resp);
          // Store into local storage
          localStorage.setItem("bearerToken", this.securityObject.bearerToken);
        })
      );
  }

  resetSecurityObject(api: string = "eKYC"): void {
    this.securityObject.userName = "";
    this.securityObject.bearerToken = "";
    this.securityObject.isAuthenticated = false;
    this.securityObject.claims = [];
    if (api == "eKYC") localStorage.removeItem("bearerToken");
  }

  // This method can be called a couple of different ways
  // *hasClaim="'claimType'"  // Assumes claimValue is true
  // *hasClaim="'claimType:value'"  // Compares claimValue to value
  // *hasClaim="['claimType1','claimType2:value','claimType3']"
  hasClaim(claimType: any, claimValue?: any) {
    let ret: boolean = false;

    // See if an array of values was passed in.
    if (typeof claimType === "string") {
      ret = this.isClaimValid(claimType, claimValue);
    } else {
      let claims: string[] = claimType;
      if (claims) {
        for (let index = 0; index < claims.length; index++) {
          ret = this.isClaimValid(claims[index]);
          // If one is successful, then let them in
          if (ret) {
            break;
          }
        }
      }
    }

    return ret;
  }

  private isClaimValid(claimType: string, claimValue?: string): boolean {
    let ret: boolean = false;
    let auth: AppUserAuth = null;

    // Retrieve security object
    auth = this.securityObject;
    if (auth) {
      // See if the claim type has a value
      // *hasClaim="'claimType:value'"
      if (claimType.indexOf(":") >= 0) {
        let words: string[] = claimType.split(":");
        claimType = words[0].toLowerCase();
        claimValue = words[1];
      } else {
        claimType = claimType.toLowerCase();
        // Either get the claim value, or assume 'true'
        claimValue = claimValue ? claimValue : "true";
      }
      // Attempt to find the claim
      ret =
        auth.claims.find(
          c =>
            c.claimType.toLowerCase() == claimType && c.claimValue == claimValue
        ) != null;
    }

    return ret;
  }

  // private doEncrypt(parameter: string, randomiv: string) {
  //   var key = CryptoJS.enc.Utf8.parse(`${environment.AES_Key}`);
  //   var iv = CryptoJS.enc.Utf8.parse(randomiv);

  //   var encrypted = CryptoJS.AES.encrypt(
  //     CryptoJS.enc.Utf8.parse(parameter),
  //     key,
  //     {
  //       keySize: 128 / 8,
  //       iv: iv,
  //       mode: CryptoJS.mode.CBC,
  //       padding: CryptoJS.pad.Pkcs7
  //     }
  //   );

  //   return encrypted;
  // }

  private getRandom(length: number) {
    return Math.floor(
      Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)
    );
  }
}
