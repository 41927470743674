import { Component, OnDestroy, OnInit } from "@angular/core";
import { ErrorDialogService } from "src/app/_services/errordialog.service";
import { EKYCService } from "src/app/_services/ekyc.service";
import { SecurityService } from "src/app/_services/security.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { forkJoin, Subscription } from "rxjs";
import { ThemeService } from 'src/app/_services/theme.service';
import { KYCStatusRequestViewModel, KYCStatusResponseViewModel } from "../_models/KYCStatusViewModel";
import { LogService } from "src/app/_services/log.service";

@Component({
  selector: "ekyc-landingpage",
  templateUrl: "./LandingPage.component.html",
  styleUrls: ["./LandingPage.component.scss"]
})
export class LandingPageComponent implements OnInit, OnDestroy {
  refNo: string = '';
  device: string = '';
  bgClass: string = '';
  subscription = new Subscription;
  mobileNo: string = '';
  sendClick: boolean = false;
  canSendSMS: boolean = true;
  isCameraDevice = false;
  showContent:boolean = false;
  kycStatus: KYCStatusResponseViewModel;

  constructor(
    public errorDialogService: ErrorDialogService,
    private ekycService: EKYCService,
    private securityService: SecurityService,
    private deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    private logService: LogService,
    public themeservice: ThemeService,
    public router: Router
  ) {
    this.bgClass = this.themeservice.getMake();
    this.route.queryParams.subscribe(params => {
      this.refNo = params.id;
      this.device = params.device;
      if (this.refNo == undefined || this.refNo == null || this.refNo.length < 8
      ) {
        this.navigate('showmsg', 'genericerr');
      }
    });
  }

  ngOnInit() {
    this.isCameraDevice = this.deviceService.isMobile() || this.deviceService.isTablet();

    if (!this.isCameraDevice) {
      this.navigate('showmsg', 'nonmobiledevice');
    }
    else {
      forkJoin([this.securityService.updateJWTToken("eKYC")]).subscribe(() => {
        this.validateData(false);
      });
    }
  }

  validateData(proceed: boolean = false) {
    let request = new KYCStatusRequestViewModel();
    request.ScreeningNo=this.refNo;
    this.subscription = this.ekycService.getKYCStatus(request).subscribe(resp => {
      if (!resp.isSucceed || resp.data == null) {
        this.navigate('showmsg', resp.message == '' ? 'genericerr' : resp.message);
        return;
      }

      this.kycStatus = resp.data;
      this.logService.setLogRocketUserName(this.kycStatus.customerName, this.kycStatus.customerName);
      this.checkCBToken(this.kycStatus).then(() => {
        if (this.kycStatus.status == 50) {
          this.navigate('showmsg', 'sqsuccess');
        }
        else if (this.kycStatus.status == 60 || this.kycStatus.status == 70) {
          if (this.kycStatus.sqStatusId == 33) {
            this.navigate('showmsg', 'error104');
          }
          else {
            this.navigate('showmsg', 'error' + resp.statusCode);
          }
        }

        if (this.device == 'M' || this.kycStatus.deviceType == 'M' || proceed) {
          // skip landing page if from mobile
          if (this.kycStatus.status == 10 || this.kycStatus.status == 20){
            this.navigate('ocr');
          }
          else if (this.kycStatus.status == 30 || this.kycStatus.status == 40) {
            this.navigate('showsq');
          }
        }
        else {
          if ((this.kycStatus.status == 30 || this.kycStatus.status == 40) 
              && this.kycStatus.sqStatusId == 33) {
                this.navigate('showmsg', 'error104');
          } else {
            this.showContent = true;
          }
        }
      });
    });
  }

  checkCBToken(data: KYCStatusResponseViewModel) {
    return new Promise<void>(resolve => {
      if (data.isCBTokenExpired || data.ocrStatusId == 24){
        // renew CB token
        let request = new KYCStatusRequestViewModel();
        request.ScreeningNo=this.refNo;
        this.ekycService.renewCBtoken(request).subscribe(resp => {
          resolve();
        });
      }
      else {
        resolve();        
      }
    });
  }

  navigate(path, message: string = '') {
    this.router.navigate([path], {
      queryParams: {
        id: this.refNo,
        device: this.device,
        msg: message
      },
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}