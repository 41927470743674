<engage-page-header></engage-page-header>
<div class="bg" [ngClass]="bgClass">
  <div class="container" *ngIf="showContent">
    <div class="row">
      <div class="scroll">
        <div class="col-12" style="padding-left: 0px;">
          <h2 class="title-ekyc">
            YOUR e-KYC DOCUMENT CHECKLIST.
          </h2>
        </div>
        <div class="row" style="text-align: center;">
          <div class="col-6">
            <img width="145" height="130" src="assets/img/ic.PNG" alt="">
            <p class="text-4">MALAYSIAN - NRIC</p>
          </div>
          <div class="col-6">
            <img width="145" height="130" src="assets/img/passport.PNG" alt="">
            <p class="text-4">FOREIGNER - PASSPORT</p>
          </div>
        </div>
        <div class="row" style="text-align: left;">
          <div class="col-12"><p class="title-1">PREPARE YOUR GOVERNMENT-ISSUED ID TYPE.</p></div>
          <div class="col-1"> <mat-icon style="vertical-align: middle;">check</mat-icon></div><div class="col-11 text-3 check-list">Malaysian: <b>NRIC</b>.</div>
          <div class="col-1"> <mat-icon style="vertical-align: middle;">check</mat-icon></div><div class="col-11 text-3">Foreigners: <b>Passport</b>.</div>
          <div class="col-12"><p class="text-3 text-note">Note: No driver's license.</p></div>

          <div class="col-12"><p class="title-1">TAKE A PHOTO OF YOUR GOVERNMENT-ISSUED ID.</p></div>
          <div class="col-12" style="text-align: center;">
            <img src="assets/img/ic_pass.png" alt="">
            <p class="text-4">GOOD</p>
          </div>
          <div class="col-1"> <mat-icon style="vertical-align: middle;">check</mat-icon></div><div class="col-11 text-3 check-list">Original documents only. No print-out, photocopy or selfie.</div>
          <div class="col-1"> <mat-icon style="vertical-align: middle;">check</mat-icon></div><div class="col-11 text-3 check-list">Fit your ID inside the frame and capture it tightly in a landscape mode.</div>
          <div class="col-1"> <mat-icon style="vertical-align: middle;">check</mat-icon></div><div class="col-11 text-3" style="margin-bottom: 10px;">Prior to submission, you will be able to see a preview of the photo you have taken. Ensure that the position of the ID is correct, and the content is readable.</div>
          <div class="col-3 fail-image">
            <img width="87" height="87" src="assets/img/ic_fail_1.png" alt="">
            <p class="text-4">NO CROP</p>
          </div>
          <div class="col-3 fail-image">
            <img width="87" height="87" src="assets/img/ic_fail_2.png" alt="">
            <p class="text-4">NO BLUR</p>
          </div>
          <div class="col-3 fail-image">
            <img width="87" height="87" src="assets/img/ic_fail_3.png" alt="">
            <p class="text-4">NO GLARE</p>
          </div>
          <div class="col-3 fail-image">
            <img width="87" height="87" src="assets/img/ic_fail_4.png" alt="">
            <p class="text-4">NO GAP</p>
          </div>
          <div class="col-12" style="margin-top:10px;"><p class="title-1">ANSWER 5 SECURITY QUESTIONS.</p></div>
          <div class="col-1"> <mat-icon style="vertical-align: middle;">check</mat-icon></div><div class="col-11 text-3">You will need to answer 5 questions to verify your identity.</div>
        </div>
      </div>
      <div class="col-12" style="margin-bottom: 30px; margin-top: 45px;">
        <button mat-raised-button class="primary" (click)="validateData(true)">Proceed</button>
      </div>
    </div>
  </div>
</div>