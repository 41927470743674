import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private bgClass: string = 'BMW';
  private engageURL: string = environment.EngageBMW_URL;

  constructor() {
    switch (window.location.hostname) {
      case environment.BMW_Hostname:
        this.engageURL = environment.EngageBMW_URL;
        this.bgClass = 'BMW';
        break;
      case environment.BMW_Hostname_Cloud:
        this.engageURL = environment.EngageBMW_Cloud_URL;
        this.bgClass = 'BMW';
        break;
      case environment.MINI_Hostname:
        this.engageURL = environment.EngageMini_URL;
        this.bgClass = 'MINI';
        break;
      case environment.MINI_Hostname_Cloud:
        this.engageURL = environment.EngageMini_Cloud_URL;
        this.bgClass = 'MINI';
        break;
      case environment.Motorrad_Hostname:
        this.engageURL = environment.EngageMotorrad_URL;
        this.bgClass = 'Motorrad';
        break;
      case environment.Motorrad_Hostname_Cloud:
        this.engageURL = environment.EngageMotorrad_Cloud_URL;
        this.bgClass = 'Motorrad';
        break;
    }
  }

  getMake() {
    return this.bgClass;
  }

  getEngageURL() {
    return this.engageURL;
  }
}