<engage-page-header></engage-page-header>
<div class="bg" [ngClass]="bgClass">
  <div class="container p-4">
    
    <div class="row">
      <div class="col">
        <h3 class="header">{{header}}</h3>
        <p class="body">
          {{message}}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button mat-raised-button class="primary" (click)="proceed()">{{btnText}}</button>
      </div>
    </div>
  </div>
</div>