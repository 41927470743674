<div [ngClass]="bgClass">
  <div class="container-fluid pt-3">
    <div class="row">
      <div class="col">
        <h3>{{data.Status}}</h3>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <span>
          {{data.Message}}
        </span>
      </div>
    </div>

    <div class="row pt-4">
      <div id="wclose" class="col">
        <button class="primary" mat-raised-button mat-dialog-close aria-label="wclose">
          Close
        </button>
      </div>
    </div>
  </div>
</div>