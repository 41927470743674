import { Component } from '@angular/core';
import { ThemeService } from 'src/app/_services/theme.service';

@Component({
  selector: 'ekyc-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent {

  bgClass: string = '';

  constructor(public themeservice: ThemeService) { 
    this.bgClass = 
    this.themeservice.getMake(); 
  }
}