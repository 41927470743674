import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {

  setLogRocketUserName(name: string, email: string): void {
    const LogRocket = (window as any).LogRocket;
    LogRocket.identify(name, {
      name: name,
      email: name,
    });
  }
}