import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OCRUIComponent } from './e-kyc/ocr/ocr.component';
import { RescanOCRMockComponent } from './e-kyc/rescanocr/rescanocr.component';
import { OCRRedirectUIComponent } from './e-kyc/ocrredirect/ocrredirect.component';
import { SecurityQuestionUIComponent } from './e-kyc/securityquestion/securityquestion.component';
import { NotFoundComponent } from './e-kyc/not-found/not-found.component';
import { ExpiredLinkComponent } from './e-kyc/expired-link/expired-link.component';
import { ShowMsgComponent } from './e-kyc/showmsg/showmsg.component';
import { LandingPageComponent } from './e-kyc/LandingPage/LandingPage.component';

const routes: Routes = [
  {
    path: '',
    component: NotFoundComponent
  },
  {
    path: 'landing',
    component: LandingPageComponent
  },
  {
    path: 'ocr',
    component: OCRUIComponent
  },  
  {
    path: 'rescanocr',
    component: RescanOCRMockComponent
  },
  {
    path: 'ocrredirect',
    component: OCRRedirectUIComponent
  },
  {
    path: 'showsq',
    component: SecurityQuestionUIComponent
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  {
    path: 'showmsg',
    component: ShowMsgComponent
  },
  {
    path: 'expired-link',
    component: ExpiredLinkComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }