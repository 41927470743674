import { NgModule } from '@angular/core';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { OCRUIComponent } from './ocr/ocr.component';
import { SecurityQuestionUIComponent } from './securityquestion/securityquestion.component';
import { RescanOCRMockComponent } from './rescanocr/rescanocr.component';
import { OCRRedirectUIComponent } from './ocrredirect/ocrredirect.component';
import { ShowMsgComponent } from './showmsg/showmsg.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ExpiredLinkComponent } from './expired-link/expired-link.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { LandingPageComponent } from './LandingPage/LandingPage.component';
import { PageHeaderComponent } from './page-header/page-header.component';

@NgModule({
  declarations: [
    LandingPageComponent,
    OCRUIComponent,
    SecurityQuestionUIComponent,
    RescanOCRMockComponent,
    OCRRedirectUIComponent,
    ShowMsgComponent,
    NotFoundComponent,
    ExpiredLinkComponent,
    PageHeaderComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule
  ],
  exports: [
    LandingPageComponent,
    OCRUIComponent,
    SecurityQuestionUIComponent,
    OCRRedirectUIComponent,
    ShowMsgComponent,
  ]
})
export class EKYCModule { }