<div [ngClass]="bgClass">
  <!-- LARGE SCREEN HEADER E-APPLICATION 2-->
  <div class="d-none d-lg-block">      
    <div class="row icon-row">
      <div class="col-6 left-logo">
        <a href={{homepage}} *ngIf="bgClass == 'MINI'">
          <img src="../../../assets/img/miniblack-logo.png" alt="" class="img-fluid mini-logo">
        </a>
      </div>   
        <div class="col-6 right-logo">
            <a href={{homepage}}>
                <img src={{engageLogoPath}} alt="" class="img-fluid engage-logo"></a>
        </div>
    </div>
  </div>
  
  <!-- SMALL SCREEN HEADER E-APPLICATION -->  
  <div class="d-lg-none">
      <div class="row icon-row">
          <div class="col-6 headerfield" *ngIf="bgClass !== 'MINI'">
            <div class="containerheader one">
              <div class="box-header" style="float:left;">
                <div class="percent">
                  <svg>
                    <circle cx="20" cy="20" r="20"></circle>
                    <circle cx="20" cy="20" r="20"></circle>
                  </svg>
                  <div class="number">
                    <span>2/4</span>
                  </div>
                </div>
              </div>
            </div>
    
            <div class="headerTitle">
              <div class="text_contact" style="float:left;" >
                <div class="text_details">e-VERIFICATION</div>
                <div class="text_next">Next: Personal Details</div>
              </div>
            </div>
          </div>
          <div class="col-6 left-logo" *ngIf="bgClass == 'MINI'">
            <a href={{homepage}}>
                <img src="../../../assets/img/miniblack-logo.png" alt="" class="img-fluid mini-logo"></a>
          </div>
          <div class="col-6 right-logo">
              <a href={{homepage}}>
                  <img src={{engageLogoPath}} alt="" class="img-fluid engage-logo"></a>
          </div>
      </div>
      <div class="row icon-row" *ngIf="bgClass == 'MINI'">
        <div class="col-6 headerfield">
          <div class="containerheader one">
            <div class="box-header" style="float:left;">
              <div class="percent">
                <svg>
                  <circle cx="20" cy="20" r="20"></circle>
                  <circle cx="20" cy="20" r="20"></circle>
                </svg>
                <div class="number">
                  <span>2/4</span>
                </div>
              </div>
            </div>
          </div>
  
          <div class="headerTitle">
            <div class="text_contact" style="float:left;" >
              <div class="text_details">e-VERIFICATION</div>
              <div class="text_next">Next: Personal Details</div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>