<engage-page-header></engage-page-header>
<div class="bg" [ngClass]="bgClass">
  <div class="container pt-3">
    <form role="form">
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <h3>SECURITY QUESTIONS.</h3>
            <p style="margin-bottom: 7px;">Answer the following questions.</p>
          </div>
        </div>
        <div id="showcard" [hidden]="isShowCardHidden">
          <div data-private>
            <div class="row">
              <div class="col-1">
                <span>1.</span>
              </div>
              <div class="col"><span [innerHTML]="sqLabel1"></span></div>
            </div>
            <div class="row">
              <div class="col-1">
              </div>
              <div class="col">
                <mat-form-field>
                  <mat-select name="selectedsq1ao" Id="selectedsq1ao" [(ngModel)]="selectedsq1ao"
                    (selectionChange)="onSQ1Select(selectedsq1ao)" required>
                    <mat-option *ngFor="let row of sqAnsOptionRes1" [value]="row"
                      [ngStyle]="{'font-family': bgClass === 'MINI' ? 'MINISerifRegular' : 'BMWRegular' }">
                      {{row.answerOptionValue}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="nameFormControl1.hasError('required')">
                    Please select answer.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row" style="margin-top: 5px;">
              <div class="col-1">
                <span>2.</span>
              </div>
              <div class="col">
                <span [innerHTML]="sqLabel2"></span>
              </div>
            </div>
            <div class="row" style="margin-top: 5px;">
              <div class="col-1">
              </div>
              <div class="col">
                <mat-form-field>
                  <mat-select name="selectedsq2ao" Id="selectedsq2ao" [(ngModel)]="selectedsq2ao"
                    (selectionChange)="onSQ2Select(selectedsq2ao)" required>
                    <mat-option *ngFor="let row of sqAnsOptionRes2" [value]="row"
                      [ngStyle]="{'font-family': bgClass === 'MINI' ? 'MINISerifRegular' : 'BMWRegular' }">
                      {{row.answerOptionValue}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="nameFormControl2.hasError('required')">
                    Please select answer.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="row" style="margin-top: 5px;">
              <div class="col-1">
                <span>3.</span>
              </div>
              <div class="col">
                <span [innerHTML]="sqLabel3"></span>
              </div>
            </div>
            <div class="row" style="margin-top: 5px;">
              <div class="col-1">
              </div>
              <div class="col">
                <mat-form-field>
                  <mat-select name="selectedsq3ao" Id="selectedsq3ao" [(ngModel)]="selectedsq3ao"
                    (selectionChange)="onSQ3Select(selectedsq3ao)" required>
                    <mat-option *ngFor="let row of sqAnsOptionRes3" [value]="row"
                      [ngStyle]="{'font-family': bgClass === 'MINI' ? 'MINISerifRegular' : 'BMWRegular' }">
                      {{row.answerOptionValue}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="nameFormControl3.hasError('required')">
                    Please select answer.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div id="divthreesq" [hidden]="is3SQHidden">
              <div class="row" style="margin-top: 5px;" >
                <div class="col-1">
                  <span>4.</span>
                </div>
                <div class="col">
                  <span [innerHTML]="sqLabel4"></span>
                </div>
              </div>
              <div class="row" style="margin-top: 5px;" >
                <div class="col-1">
                </div>
                <div class="col">
                  <mat-form-field>
                    <mat-select name="selectedsq4ao" Id="selectedsq4ao" [(ngModel)]="selectedsq4ao"
                      (selectionChange)="onSQ4Select(selectedsq4ao)" required>
                      <mat-option *ngFor="let row of sqAnsOptionRes4" [value]="row"
                        [ngStyle]="{'font-family': bgClass === 'MINI' ? 'MINISerifRegular' : 'BMWRegular' }">
                        {{row.answerOptionValue}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="nameFormControl4.hasError('required')">
                      Please select answer.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-1">
                  <span>5.</span>
                </div>
                <div class="col">
                  <span [innerHTML]="sqLabel5"></span>
                </div>
              </div>
              <div class="row">
                <div class="col-1">
                </div>
                <div class="col">
                  <mat-form-field>
                    <mat-select name="selectedsq5ao" Id="selectedsq5ao" [(ngModel)]="selectedsq5ao"
                      (selectionChange)="onSQ5Select(selectedsq5ao)" required>
                      <mat-option *ngFor="let row of sqAnsOptionRes5" [value]="row"
                        [ngStyle]="{'font-family': bgClass === 'MINI' ? 'MINISerifRegular' : 'BMWRegular' }">
                        {{row.answerOptionValue}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="nameFormControl5.hasError('required')">
                      Please select answer.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row pt-4 pb-4">
              <div class="col">
                <button mat-raised-button class="primary" (click)="submitRandomSQ()" [disabled]="isSubmitDisabled"
                  [disabled]="isSubmitDisabled">{{btnText}}</button>
              </div>
            </div>
          </div>
          <br /><br />
        </div>
      </div>
    </form>
  </div>
</div>