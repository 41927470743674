import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EKYCService } from '../../_services/ekyc.service';
import { environment } from 'src/environments/environment';
import { ThemeService } from 'src/app/_services/theme.service';
import { SecurityService } from 'src/app/_services/security.service';
import { Subscription, forkJoin } from 'rxjs';
import { KYCStatusRequestViewModel, KYCStatusResponseViewModel } from '../_models/KYCStatusViewModel';
import { LogService } from 'src/app/_services/log.service';

@Component({
  selector: 'ekyc-showmsg',
  templateUrl: './showmsg.component.html',
  styleUrls: ['./showmsg.component.scss']
})
export class ShowMsgComponent implements OnInit {

  kycShowMsg: string;
  showCommonMsg: boolean = false;
  showSQFail: boolean = false;
  showSQSuccess: boolean = false;
  showLinkExpired: boolean = false;
  genericerr: boolean = false;
  browsernotsupport: boolean = false;
  refNo: string;
  device: string = '';
  subscription = new Subscription;
  kycStatus: KYCStatusResponseViewModel;

  nonmobiledevice: boolean = false;
  error9820: boolean = false;
  error9822: boolean = false;
  error9200: boolean = false;
  error9100: boolean = false;
  error9210: boolean = false;
  error9300: boolean = false;
  error9400: boolean = false;
  error9800: boolean = false;
  error9801: boolean = false;
  error9810: boolean = false;
  error9835: boolean = false;
  error621: boolean = false;

  bgClass: string = '';
  header: string = 'Notification';
  message: string = environment.Generic_Error_Message;
  btnText: string = 'Home';

  constructor(
    private ekycService: EKYCService,
    private route: ActivatedRoute,
    private securityService: SecurityService,
    private logService: LogService,
    public themeservice: ThemeService
  ) {
    this.bgClass = this.themeservice.getMake();
  }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      this.refNo = params["id"];
      this.device = params["device"];
      this.kycShowMsg = params["msg"];
    });
    
    if (this.refNo != null && this.refNo != '') {
      // get status to confirm to continue from desktop or mobile
      forkJoin([this.securityService.updateJWTToken("eKYC")]).subscribe(
        ([ekycResp]) => {
          if (ekycResp === true) {
            this.validateData();
          } 
        }
      );
    }
    
    this.kycShowMsg = this.kycShowMsg.toLowerCase();

    if (this.kycShowMsg == "sqsuccess") {
      this.header = 'Verification Successful.';
      this.message = 'Continue your e-Application journey from your desktop. Should you wish to continue another time, you may pick up from where you left off by clicking the link we sent to your email.';
      this.btnText = 'Close';
    }
    else if (this.kycShowMsg == "sqfail") {
      this.header = 'Error 104.';
      this.message = 'We are unable to proceed with your application. Please contact customer interaction centre at +603-27192688 for further assistant.';
    }
    else if (this.kycShowMsg == "linkexpired")
    {
      this.header = 'Error.';
      this.message = 'Link expired. Should you wish to continue another time, you may pick up from where you left off by clicking the link we sent to your email.';
    }
    else if (this.kycShowMsg == "genericerr")
    {
      this.header = 'Error.';
      this.message = environment.Generic_Error_Message;
    }
    else if (this.kycShowMsg == "browsernotsupport"){
      this.header = 'Browser Not Supported.';
      this.message = 'Please use the default browser for your operating system. Example, Safari for iOS or Chrome for Android. For further assistance contact our Customer Interaction Center at 1800 88 3000.';
    }
    else if (this.kycShowMsg == "nonmobiledevice"){
      this.header = 'Environment Not Supported.';
      this.message = 'This feature requires a smartphone camera to function. Please reopen the link in your smartphone to proceed. For further assistance contact our Customer Interaction Center at 1800 88 3000.';
    }
    else if (this.kycShowMsg == "error100"){
      this.header = 'Error 100.';
      this.message = 'We are unable to process your online request. Please proceed to our nearest dealership for manual application.';
    }
    else if (this.kycShowMsg == "error103"){
      this.header = 'Error 103.';
      this.message = 'One More Try, You will have another attempt to retry in order to proceed to the next steps.';
    }
    else if (this.kycShowMsg == "error104"){
      this.header = 'Error 104.';
      this.message = 'We are unable to proceed with your application. Please contact customer interaction centre at +603-27192688 for further assistant.';
    }
    else if (this.kycShowMsg == "error105"){
      this.header = 'Error 105.';
      this.message = 'We are unable to proceed with your application. Please try again in the next 24 hours.';
    }
    else if (this.kycShowMsg == "error106"){
      this.header = 'Error 106.';
      this.message = 'We are unable to proceed with your application. Please contact customer interaction centre at +603-27192688 for further assistant.';
    }
    else if (this.kycShowMsg == "error107"){
      this.header = 'Error 107.';
      this.message = 'We are unable to proceed with your application. Please contact customer interaction centre at +603-27192688 for further assistant.';
    }
    else if (this.kycShowMsg == "error108"){
      this.header = 'Error 108.';
      this.message = 'We are unable to proceed with your application. Please contact customer interaction centre at +603-27192688 for further assistant.';
    }
    else if (this.kycShowMsg == "error109"){
      this.header = 'Error 109.';
      this.message = 'Identity Card / Passport not readable. Ensure you are using the original physical document. Please try again.';
    }
    else if (this.kycShowMsg == "error110"){
      this.header = 'Error 110.';
      this.message = 'Identity Card / Passport not valid . Ensure you are using the valid document. Malaysian: Identity card, Foreigner : Passport  Please try again.';
    }
    else if (this.kycShowMsg == "error405"){
      this.header = 'Error 405.';
      this.message = 'We were unable to submit your application at this time. Please try again using the link in your email in 1 hour. If the issue persists please contact our Customer Interaction Centre at +603-2719 2688 for further assistance.';
    }
    else if (this.kycShowMsg == "error402"){
      this.header = 'Error 402.';
      this.message = 'We were unable to submit your application at this time. Please try again later using the link in your email. If the issue persists please contact our Customer Interaction Centre at +603-2719 2688 for further assistance.';
    }
    else if (this.kycShowMsg == "error9820"){
      this.header = 'Browser Settings Not Supported.';
      this.message = 'Access may not have been granted to your gallery, files or camera. Please ensure these options are enabled in your browser settings. For further assistance contact our Customer Interaction Center at 1800 88 3000.';
    }
    else if (this.kycShowMsg == "error9822"){
      this.header = 'Browser Not Supported.';
      this.message = 'Please use the default browser for your operating system. Example, Safari for iOS or Chrome for Android. For further assistance contact our Customer Interaction Center at 1800 88 3000.';
    }
    else if (this.kycShowMsg == "error9200"){
      this.header = 'Session Has Expired.';
      this.message = 'This session may have expired. Please click on the link within the email you received to start a new session. If the issue persists please contact our Customer Interaction Center at 1800 88 3000 for further assistance.';
    }
    else if (this.kycShowMsg == "error9100"){
      this.header = 'Connection Lost.';
      this.message = 'The connection to our partner may have been lost. Please try again later by clicking click on the link within the email you received. If the issue persists please contact our Customer Interaction Center at 1800 88 3000 for further assistance.';
    }
    else if (this.kycShowMsg == "error9210"){
      this.header = 'Session Has Expired.';
      this.message = 'This session may have expired. Please click on the link within the email you received to start a new session. If the issue persists please contact our Customer Interaction Center at 1800 88 3000 for further assistance.';
    }
    else if (this.kycShowMsg == "error9300"){
      this.header = 'Connection Lost.';
      this.message = 'The connection to our partner may have been lost. Please try again later by clicking click on the link within the email you received. If the issue persists please contact our Customer Interaction Center at 1800 88 3000 for further assistance.';
    }
    else if (this.kycShowMsg == "error9400"){
      this.header = 'Verification Failed.';
      this.message = 'An unexpected error occurred during our verification process. Please refresh the page and try again. If you are unable to proceed please contact our Customer Interaction Center at 1800 88 3000 for further assistance.';
    }
    else if (this.kycShowMsg == "error9800"){
      this.header = 'Connection Lost.';
      this.message = 'The connection to our partner may have been lost. Please try again later by clicking click on the link within the email you received. If the issue persists please contact our Customer Interaction Center at 1800 88 3000 for further assistance.';
    }
    else if (this.kycShowMsg == "error9801"){
      this.header = 'Unexpected Error.';
      this.message = 'An unexpected error occurred during our verification process. Please refresh the page and try again. If you are unable to proceed please contact our Customer Interaction Center at 1800 88 3000 for further assistance.';
    }
    else if (this.kycShowMsg == "error9810"){
      this.header = 'Connection Lost.';
      this.message = 'The connection to our partner may have been lost. Please try again later by clicking click on the link within the email you received. If the issue persists please contact our Customer Interaction Center at 1800 88 3000 for further assistance.';
    }
    else if (this.kycShowMsg == "error9835"){
      this.header = 'Image Clarity Insufficient.';
      this.message = 'The images taken may not be clear enough for our system to process it. Please ensure there is sufficient lighting and no shadows are covering your ID. If the issue persists please contact our Customer Interaction Center at 1800 88 3000 for further assistance.';
    }
    else if (this.kycShowMsg == "error621"){
      this.header = 'Service Error.';
      this.message = 'The connection to our partner may have been lost. Please try again later by clicking click on the link within the email you received. If the issue persists please contact our Customer Interaction Center at 1800 88 3000 for further assistance.';
    }
  }

  validateData() {
    if (this.refNo != null && this.refNo != '') {
      let request = new KYCStatusRequestViewModel();
      request.ScreeningNo = this.refNo;
      this.subscription = this.ekycService.getKYCStatus(request).subscribe(resp => {
        if (!resp.isSucceed) {
          this.kycShowMsg = "";
          this.header = resp.message;
          this.message = resp.messageDetail;
        }
        else {
          this.kycStatus = resp.data;
          this.logService.setLogRocketUserName(this.kycStatus.customerName, this.kycStatus.customerName);
          if (this.kycStatus != null && this.kycStatus.source == 'CFE' && this.kycStatus.deviceType == 'M' && this.kycShowMsg == "sqsuccess") {
            this.message = 'Please click Proceed to continue your e-Application journey. Should you wish to continue another time, you may pick up from where you left off by clicking the link we sent to your email.';
            this.btnText = 'Proceed';
          }
        }
      });
    }
  }

  proceed() {
    if (this.kycStatus != null && (this.device == 'M' || this.kycStatus.deviceType == 'M')) {
      if (this.kycStatus.source == 'CFE') {
        window.location.href = this.themeservice.getEngageURL() + 'landing?source=CFE&from=kyc&Id=' + this.kycStatus.sourceReferenceNo;
      }
      else {
        window.close();
      }
    }
    else {
      window.location.href = this.themeservice.getEngageURL();
    }
  }
}