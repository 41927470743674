import { Component, OnInit } from '@angular/core';
import { RedirectOCRRequestViewModel } from 'src/app/e-kyc/_models/redirectOCRRequestViewModel';
import { ErrorDialogService } from 'src/app/_services/errordialog.service';
import { EKYCService } from '../../_services/ekyc.service';
import { KYCCBDetail } from 'src/app/e-kyc/_models/kycCBDetail';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ekyc-mock-ocrredirect',
  templateUrl: './ocrredirect.component.html'
})

export class OCRRedirectUIComponent implements OnInit {
  transactionReference: string;
  transactionStatus: string;
  refNo: string;
  kycCBDetail: KYCCBDetail;
  cbDetails: any[];
  ocrShowMsg: string;
  errorCode: string;
  customerInternalReference: string;

  constructor(
    public errorDialogService: ErrorDialogService,
    private ekycService: EKYCService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.transactionReference = params['transactionReference'];
      this.transactionStatus = params['transactionStatus'];
      this.errorCode = params['errorCode'];
      this.customerInternalReference = params['customerInternalReference'];
    });
    this.ocrShowMsg = "";

    //set default first
    if (this.transactionReference == "0" || this.transactionReference == "") {
      this.ocrShowMsg = "Pending...";
    }
    else {
      this.redirectOCR();
    }
  }

  redirectOCR() {
    // tslint:disable-next-line:prefer-const
    let request = new RedirectOCRRequestViewModel();
    request.transactionReference = this.transactionReference;
    request.transactionStatus = this.transactionStatus;
    request.errorCode = this.errorCode;
    request.customerInternalReference = this.customerInternalReference;

    return new Promise<void>((resolve) => {
      this.ekycService.redirectOCR(request)
        .subscribe(kycCBDetail => {
          //for parallel process, show success message for proceed to the SQ stage.
          if (kycCBDetail.isSucceed && this.transactionStatus == 'SUCCESS') {
            this.ocrShowMsg = environment.Message_ImageUploaded;
          }
          else {
            this.ocrShowMsg = environment.Message_OCRUnsuccessful;
          }
          resolve();
        });
    });
  }
}