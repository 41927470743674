import { Component, OnDestroy, OnInit } from "@angular/core";
import { ErrorDialogService } from "src/app/_services/errordialog.service";
import { EKYCService } from "../../_services/ekyc.service";
import { SecurityService } from "src/app/_services/security.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subscription, forkJoin, interval } from "rxjs";
import { environment } from "src/environments/environment";
import { ThemeService } from 'src/app/_services/theme.service';
import { KYCStatusRequestViewModel, KYCStatusResponseViewModel } from "../_models/KYCStatusViewModel";
import { GetOCRRequestViewModel } from "../_models/GetOCRURlViewModel";
import { LogService } from "src/app/_services/log.service";

const isIEOrEdge = /msie\s|trident\/|edge\/|firefox\//i.test(
  window.navigator.userAgent
);

@Component({
  selector: "ekyc-ocr",
  templateUrl: "./ocr.component.html",
  styleUrls: ["./ocr.component.scss"]
})
export class OCRUIComponent implements OnInit, OnDestroy {
  ieBrowser: boolean = isIEOrEdge;
  refNo: string = '';
  device: string = '';
  trustediFrameURL: SafeUrl;
  isiFrameURLReady: boolean = false;
  isProceedDisabled: boolean = true;
  isRefreshIFrame: boolean = true;
  sub = new Subscription;
  timercount: number;
  bgClass: string = '';
  subscription = new Subscription;
  kycStatus: KYCStatusResponseViewModel;
  btnText: string = "Proceed";
  
  constructor(
    public errorDialogService: ErrorDialogService,
    private ekycService: EKYCService,
    private deviceService: DeviceDetectorService,
    private route: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private securityService: SecurityService,
    public themeservice: ThemeService,
    private logService: LogService,
    public router: Router
  ) {
    this.bgClass = this.themeservice.getMake();
  }

  ngOnInit() {
    const isCameraDevice = this.deviceService.isMobile() || this.deviceService.isTablet();
    if (!isCameraDevice)
      this.navigate('showmsg', 'nonmobiledevice');

    //redirect to browser not supported
    if (this.ieBrowser)
      this.navigate('showmsg', 'browsernotsupport');

    this.route.queryParams.subscribe(params => {
      this.refNo = params["id"];
      this.device = params["device"];
    });

    if (this.refNo == undefined || this.refNo == null || this.refNo.length < 8) {
      this.navigate('showmsg', 'genericerr');
    }

    //get JWT Token to cater from PC
    // this.fngetEKYCToken();
    forkJoin([this.securityService.updateJWTToken("eKYC")]).subscribe(
      ([ekycResp]) => {
        if (ekycResp === true) {
          this.validateData();
        } else {
          this.navigate('showmsg', 'genericerr');
        }
      }
    );

    //set default first
    this.trustediFrameURL = this.sanitizer.bypassSecurityTrustResourceUrl(
      location.origin
    );
  }

  validateData() {
    let request = new KYCStatusRequestViewModel();
    request.ScreeningNo = this.refNo;
    this.subscription = this.ekycService.getKYCStatus(request).subscribe(resp => {
      if (!resp.isSucceed || resp.data == null) {
        this.navigate('showmsg', 'genericerr');
        return;
      }

      this.kycStatus = resp.data;      
      if (this.kycStatus.status == 10 || this.kycStatus.status == 20) {
        this.checkCBToken(this.kycStatus).then(() => {
          this.bindIframeURl(this.kycStatus.screeningID);
          this.initiateKYCStatusTimer();
        });
      }
      else if (this.kycStatus.status == 30 || this.kycStatus.status == 40)
      {
        this.navigate('showsq');
      }
      else if (this.kycStatus.status == 50) {
        this.navigate('showmsg', 'sqsuccess');
      }
      else if (this.kycStatus.status >= 60) {
        this.navigate('showmsg', 'error' + resp.statusCode);
      }
    });
  }

  checkCBToken(data: KYCStatusResponseViewModel) {
    return new Promise<void>(resolve => {
      if (data.ocrStatusId == 24) {
        // renew CB token
        let request = new KYCStatusRequestViewModel();
        request.ScreeningNo=this.refNo;
        this.ekycService.renewCBtoken(request).subscribe(resp => {
          resolve();
        });
      }
      else {
        resolve();        
      }
    });
  }

  bindIframeURl(screeningId) {
    let request = new GetOCRRequestViewModel();
    request.screeningID = screeningId;
    request.screeningNo = this.refNo;
    this.subscription = this.ekycService.GetOCRURL(request).subscribe(resp => {
      if (!resp.isSucceed || resp.data == null) {
        this.navigate('showmsg', 'genericerr');
      }
      
      if (resp.data.iframeURL != '') {
        this.trustediFrameURL = this.sanitizer.bypassSecurityTrustResourceUrl(
          resp.data.iframeURL);
        this.isiFrameURLReady = true;
      }
      else {
        this.navigate('showmsg', 'genericerr');
      }
    });
  }

  initiateKYCStatusTimer() {
    this.timercount = 0;
    //looping every 5 seconds
    this.sub =interval(5000).subscribe(() => {
      //set the flag to hide the loader.
      localStorage.setItem("HideLoader", "true");
      let request = new KYCStatusRequestViewModel();
      request.ScreeningNo = this.refNo;
      //get KYC status
       this.ekycService.getKYCStatus(request).subscribe(resp => {
        this.kycStatus = resp.data;
        this.logService.setLogRocketUserName(this.kycStatus.customerName, this.kycStatus.customerName);
        if (resp.isSucceed) {
          if (this.kycStatus.status >= 60) {
            this.navigate('showmsg', 'error' + resp.statusCode);
          }    

          if (this.kycStatus.status >= 30) {
            // proceed to security question
            this.isRefreshIFrame = false;
            this.sub.unsubscribe();
            this.isProceedDisabled=false;
          } 
          else if (this.kycStatus.ocrStatusId == 24) {
            this.isRefreshIFrame = false;
            this.sub.unsubscribe();
            this.isProceedDisabled=false;
            this.btnText = "Retry";
          }
        } 
        else {
          if (resp.message !== '') {
            this.navigate('showmsg', resp.message);
          }
        }
      });

      this.timercount += 1;
      if (this.timercount == 240) {
        this.sub.unsubscribe(); //once exceed 20 minutes, exit loop
      }
    });
  }

  navigate(path, message: string = '') {
    this.router.navigate([path], {
      queryParams: {
        id: this.refNo,
        device: this.device,
        msg: message
      },
    });
  }

  proceed() {
    if (this.btnText == "Retry") {
      this.navigate('landing');
    }
    else {
      this.navigate('showsq');
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}