import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpInterceptorModule } from './_security/http-interceptors';
import { SecurityService } from './_services/security.service';
import { RouterModule } from '@angular/router';
import { ErrorDialogService } from './_services/errordialog.service';
import { ErrorDialogComponent } from './_shared/error-dialog/errordialog.component';
import { LoaderComponent } from './_shared/loader/loader.component';
import { LoaderService } from './_services/loader.service';
import { BrowserModule } from '@angular/platform-browser';
import { EKYCModule } from './e-kyc/ekyc.module';
import { EKYCService } from './_services/ekyc.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';

// SMOSS Engage Integration

import { MatSelectModule } from '@angular/material/select';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    ErrorDialogComponent,
    LoaderComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    MatDialogModule,
    HttpClientModule,
    RouterModule,
    AppRoutingModule,
    HttpInterceptorModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    MatSliderModule,
    MatCardModule,
    MatIconModule,
    MatExpansionModule,
    MatButtonModule,
    EKYCModule,
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    })
  ],
  entryComponents: [
    EKYCModule
  ],
  providers: [
    SecurityService,
    ErrorDialogService, 
    LoaderService, 
    EKYCService,
    {provide: LocationStrategy, useClass: PathLocationStrategy}
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
