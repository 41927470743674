<div class="bg" [ngClass]="bgClass">
  <div class="card">
    <div class="card-header card-header-primary">
      <h4 class="card-title ">eKYC - Rescan OCR</h4>
    </div>
    <form class="form-horizontal " role="form" style="padding:15px" name="RescanOCRMockForm">
      <div class="row">
        <div class="col-md-5">
          <mat-form-field class="example-full-width">
            <input matInput placeholder="Mobile Number" name="mobileNumber" id="mobileNumber">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="example-button-row">
            <button mat-raised-button class="primary" (click)="rescanOCR()">Rescan OCR</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>