import { Injectable, NgModule } from "@angular/core";
import { Observable, of, throwError, forkJoin } from "rxjs";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HTTP_INTERCEPTORS,
  HttpHeaders,
  HttpErrorResponse,
  HttpResponse
} from "@angular/common/http";

import { catchError, map, finalize } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import { ErrorDialogService } from "../_services/errordialog.service";
import { LoaderService } from "../_services/loader.service";
import { environment } from "src/environments/environment";
import { SecurityService } from "../_services/security.service";

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public errorDialogService: ErrorDialogService,
    public loaderService: LoaderService,
    public securityService: SecurityService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var isLPCall = localStorage.getItem("IsLPCall");
    var hideLoader = localStorage.getItem("HideLoader");
    const reqSource = this.getRequestedSource(req.url);
    if (isLPCall != null && isLPCall.length == 1) {
      this.loaderService.show();
      localStorage.removeItem("IsLPCall");
      return next.handle(req);
    } else if (hideLoader == "true") {
      const token = this.securityService.getJwtToken(reqSource); // localStorage.getItem("bearerToken");
      localStorage.removeItem("HideLoader");
      if (token) {
        req = req.clone({
          headers: req.headers.set("Authorization", "Bearer " + token)
        });
      }

      if (!req.headers.has("Content-Type")) {
        req = req.clone({
          headers: req.headers.set("Content-Type", "application/json")
        });
      }

      req = req.clone({
        headers: req.headers.set("Accept", "application/json")
      });

      return next.handle(req).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
          return event;
        }),
        finalize(() => this.loaderService.hide()),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.handleRefreshToken(reqSource, req, error, next);
          } 
          // else if (error.status == 0) {
          //   let data = {
          //     Message:
          //       "We are unable to proceed with your application. Please try again in the next 24 hours.",
          //     Status: "Error 105"
          //   };
          //   this.errorDialogService.openDialog(data);
          // } else {
          //   let data = {
          //     Message: error && error.message ? error.message : "",
          //     Status: error.status.toString()
          //   };
          //   this.errorDialogService.openDialog(data);
          // }
          return throwError(error);
        })
      );
    } else {
      this.loaderService.show();
      localStorage.removeItem("IsLPCall");
      const token = this.securityService.getJwtToken(reqSource); // localStorage.getItem("bearerToken");
      if (token) {
        req = req.clone({
          headers: req.headers.set("Authorization", "Bearer " + token)
        });
      }

      if (!req.headers.has("Content-Type")) {
        req = req.clone({
          headers: req.headers.set("Content-Type", "application/json")
        });
      }

      req = req.clone({
        headers: req.headers.set("Accept", "application/json")
      });

      return next.handle(req).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
          }
          return event;
        }),
        finalize(() => this.loaderService.hide()),
        catchError((error: HttpErrorResponse) => {
          if (error.status == 401) {
            this.handleRefreshToken(reqSource, req, error, next);
          }
          //  else if (error.status == 0) {
          //   let data = {
          //     Message:
          //       "We are unable to proceed with your application. Please try again in the next 24 hours.",
          //     Status: "Error 105"
          //   };
          //   this.errorDialogService.openDialog(data);
          // } else {
          //   let data = {
          //     Message: error && error.message ? error.message : "",
          //     Status: error.status.toString()
          //   };
          //   this.errorDialogService.openDialog(data);
          // }
          return throwError(error);
        })
      );
    }
  }
  handleRefreshToken(
    reqSource: string,
    newReq: HttpRequest<any>,
    error: any,
    next: HttpHandler
  ) {
    forkJoin([this.securityService.updateJWTToken(reqSource)]).subscribe(
      ([jwtResp]) => {
        if (jwtResp === true) {
          let token = "";
          token = this.securityService.getJwtToken(reqSource);
          if (token) {
            const authToken = "Bearer " + token;
            const req = newReq.clone({
              headers: new HttpHeaders({
                "Content-Type": "application/json",
                // "Access-Control-Allow-Origin": environment.UI_ENDPOINT,
                Authorization: authToken
              })
            });

            return next.handle(req).pipe(
              finalize(() => this.loaderService.hide()),
              catchError((error, caught) => {
                this.handleAuthError(error);
                return of(error);
              }) as any
            );
          }
        } else {
          this.handleAuthError(error);
          return of(error);
        }
        return false;
      }
    );
  }
  getRequestedSource(url: string) {
    if (url.includes(environment.API_ENDPOINT)) {
      return "eKYC";
    } else {
      return "";
    }
  }
  /**
   * manage errors
   * @param err
   * @returns {any}
   */
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    // handle your auth error or rethrow
    if (err.status === 401) {
      this.router.navigate(["./landing"], { relativeTo: this.activatedRoute });
    }
    if (err.status === 203) {
      this.router.navigate(["./expired-link"], {
        relativeTo: this.activatedRoute
      });
    } else {
    }
    return of(err.message);
  }
}

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    }
  ]
})
export class HttpInterceptorModule { }
