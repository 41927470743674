import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResultViewModel } from '../e-kyc/_models/resultViewModel';
import { KYCScreeningRequestViewModel } from '../e-kyc/_models/kycScreeningRequestViewModel';
import { SendSMSRequestViewModel } from '../e-kyc/_models/sendSMSRequestViewModel';
import { CheckOCRRequestViewModel } from '../e-kyc/_models/checkOCRRequestViewModel';
import { GetCBDetailRequestViewModel } from '../e-kyc/_models/getCBDetailRequestViewModel';
import { UpdateScreeningRequestViewModel } from '../e-kyc/_models/updateScreeningRequestViewModel';
import { RedirectOCRRequestViewModel } from '../e-kyc/_models/redirectOCRRequestViewModel';
import { KYCCBDetail } from '../e-kyc/_models/kycCBDetail';
import { GetSecurityQuestionRequestViewModel } from '../e-kyc/_models/getSecurityQuestionRequestViewModel';
import { KYCRandomSecQuesAnsOption } from 'src/app/e-kyc/_models/kycRandomSecQuesAnsOption';
import { environment } from 'src/environments/environment';
import { SubmitSecurityQuestionRequestViewModel } from '../e-kyc/_models/SubmitSecurityQuestionRequestViewModel';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    // "Access-Control-Allow-Origin": environment.UI_ENDPOINT
  })
};

@Injectable()
export class EKYCService {

  endpoint: string = '/api/V1';

  constructor(private http: HttpClient) { }

  private messageSource = new BehaviorSubject('Default message');
  private navMessageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();
  currentNavMessage = this.messageSource.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  navbarFunction(NavbarVal: string) {
    this.navMessageSource.next(NavbarVal)
  }
  
  getKYCStatus(model: Object): Observable<ResultViewModel> {
    return this.http.post<ResultViewModel>(`${environment.API_ENDPOINT}${this.endpoint}/KYC/GetStatus`, model, httpOptions);
  }

  getMobileNo(model: Object): Observable<ResultViewModel> {
    return this.http.post<ResultViewModel>(`${environment.API_ENDPOINT}${this.endpoint}/KYC/GetMobileNo`, model, httpOptions);
  }

  GetOCRURL(model: Object): Observable<ResultViewModel> {
    return this.http.post<ResultViewModel>(`${environment.API_ENDPOINT}${this.endpoint}/KYC/GetOCRURL`, model, httpOptions);
  }

  getrandomsecques(secquestionreq: GetSecurityQuestionRequestViewModel): Observable<ResultViewModel> {
    return this.http.post<ResultViewModel>(`${environment.API_ENDPOINT}${this.endpoint}/KYC/GetRandomSecurityQuestion`, secquestionreq, httpOptions);
  }

  submitrandomsq(submitsqreq: SubmitSecurityQuestionRequestViewModel): Observable<ResultViewModel> {
    return this.http.post<ResultViewModel>(`${environment.API_ENDPOINT}${this.endpoint}/KYC/SubmitRandomSQ`, submitsqreq, httpOptions);
  }

  redirectOCR(redirectocrreq: RedirectOCRRequestViewModel): Observable<ResultViewModel> {
    return this.http.post<ResultViewModel>(`${environment.API_ENDPOINT}${this.endpoint}/KYC/UpdateOCRStatus`, redirectocrreq, httpOptions);
  }

  renewCBtoken(model: Object): Observable<ResultViewModel> {
    return this.http.post<ResultViewModel>(`${environment.API_ENDPOINT}${this.endpoint}/KYC/RenewCBToken`, model, httpOptions);
  }



  startCustomerScreening(kycscreeningreq: KYCScreeningRequestViewModel): Observable<ResultViewModel> {
    return this.http.post<ResultViewModel>(`${environment.API_ENDPOINT}${this.endpoint}/startcustomerscreening`, kycscreeningreq, httpOptions);
  }

  updatescreeningdetails(updatescreeningreq: UpdateScreeningRequestViewModel): Observable<ResultViewModel> {
    return this.http.post<ResultViewModel>(`${environment.API_ENDPOINT}${this.endpoint}/updatescreeningdetails`, updatescreeningreq, httpOptions);
  }

  sendSMS(sendsmsreq: SendSMSRequestViewModel): Observable<ResultViewModel> {
    return this.http.post<ResultViewModel>(`${environment.API_ENDPOINT}${this.endpoint}/sendsms`, sendsmsreq, httpOptions);
  }

  checkOCRStatus(checkOCRreq: CheckOCRRequestViewModel): Observable<ResultViewModel> {
    return this.http.post<ResultViewModel>(`${environment.API_ENDPOINT}${this.endpoint}/checkocr`, checkOCRreq, httpOptions);
  }

  rescanOCR(checkOCRreq: CheckOCRRequestViewModel): Observable<ResultViewModel> {
    return this.http.post<ResultViewModel>(`${environment.API_ENDPOINT}${this.endpoint}/rescanocr`, checkOCRreq, httpOptions);
  }

  getCBDetail(cbdetailreq: GetCBDetailRequestViewModel): Observable<KYCCBDetail> {
    return this.http.post<KYCCBDetail>(`${environment.API_ENDPOINT}${this.endpoint}/getcbdetail`, cbdetailreq, httpOptions);
  }

  renewtoken(cbdetailreq: GetCBDetailRequestViewModel): Observable<KYCCBDetail> {
    return this.http.post<KYCCBDetail>(`${environment.API_ENDPOINT}${this.endpoint}/renewtoken`, cbdetailreq, httpOptions);
  }

  getSettings(): Observable<any> {
    return this.http.get<any>('/assets/files/appsettings.json');
  }
}