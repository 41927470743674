import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ErrorDialogComponent } from '../_shared/error-dialog/errordialog.component';

@Injectable()
export class ErrorDialogService {

  constructor(private dialog: MatDialog) { }

  openDialog(data: any) {
    this.dialog.open(ErrorDialogComponent, {
      width: '300px', data
    });
  }

  openDialogOcrFail(data: any) {
    const dialogRef = this.dialog.open(ErrorDialogComponent, {
      disableClose: true,
      width: '300px', data
    });

    dialogRef.afterClosed().subscribe(() => {
      let type = `${environment.EngageBMW_URL}`;

      if (data.url === "MINI")
        type = `${environment.EngageMini_URL}`;
      else if (data.url === "SMOSS")
        type = 'https://shop.bmw.com.my/';

      window.location.href = type;
    });
  }

}